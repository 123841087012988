<template>
  <div class="row">
    <div class="col-md">
      <b-form @submit.stop.prevent="formOnSubmit" enctype="multipart/form-data">
        <!-- Input Modal Trigger -->
        <b-form-group
          id="input-group-trigger-modal-patient"
          label="Karyawan :"
          label-for="input-trigger-modal-patient"
        >
          <b-form-input
            id="input-trigger-modal-patient"
            v-model="display.employee_name"
            placeholder="Pilih karyawan"
            readonly
            @click="$bvModal.show('modal-employee')"
            
          >
          </b-form-input>
          <small class="text-danger">{{ error.employee_id }}</small>
        </b-form-group>

         <!-- Remuneration Select
          <b-form-group
            id="input-group-patient"
            label="Pilih Remunerasi:"
            label-for="input-patient"
            v-if="remunerationLoad == true"
          >
            <treeselect
              v-model="form.remuneration_id"
              :multiple="true"
              :options="remunerations"
            />
            <small class="text-danger">{{ error.remuneration_id }}</small>
          </b-form-group> -->
          <div class="row">
            <div class="col-md-8">

                <div class="table-responsive" v-if="remunerationLoad == true">
                <table class="table table-vertical-center">
                  <thead>
                    <tr>
                      <th class="p-0" style="width: 100px"></th>
                      <th class="p-0" style="min-width: 100px"></th>
                      <th class="p-0" style="min-width: 100px"></th>
                      <th class="p-0" style="min-width: 100px"></th>
                      <!-- <th class="p-0" style="min-width: 125px"></th> -->
                      <!-- <th class="p-0" style="min-width: 110px"></th>
                      <th class="p-0" style="min-width: 150px"></th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="tableDisplay.paymentDisplay == true">
                      <td colspan="3">
                        <span class="font-weight-bold text-info">Pembayaran</span>
                      </td>
                    </tr>

                    <!-- pembayaran -->
                    <template v-for="(item, i) in paymentRemuneration">
                      <tr v-bind:key="i">
                        <td class="pl-0">
                            <span class="">{{item.remuneration_type_name}}</span> 
                            <!-- it's for remuneration payment name  -->
                        </td>
                        <td class="text-right">
                          <div class="btn-group btn-group-sm">
                            <b-button
                              squared
                              class="btn btn-sm btn-info square"
                              @click="btnMinQtyPayment(item.id, item.qty, item.ammount, i)"
                            ><i class="fas fa-sm fa-fw fa-minus"></i></b-button>
                            <!-- <span><button class="btn btn-sm btn-block bg-white" disabled>{{ item.qty }}</button></span> -->
                            <b-form-input
                              style="width: 50px"
                              class="input-group text-center"
                              v-model="item.qty"
                              @keyup="btnConfirmPayment(item.qty,i)"
                            >
                            </b-form-input>
                            <b-button
                              squared
                              class="btn btn-sm btn-info"
                              @click="btnAddQtyPayment(item.id, item.qty, item.ammount, i)"
                            ><i class="fas fa-sm fa-fw fa-plus"></i></b-button>
                          </div>
                        </td>
                        <td class="text-right">
                          <span
                            class="font-align-bold"
                          >
                            
                            {{"Rp "+parseInt(item.ammountPayroll).toLocaleString('id-ID')}}

                            <!-- it's for amount -->
                          </span>
                        </td>
                        <td>
                           <b-form-input
                              v-bind:id="'paymentNote'+i"
                              placeholder="Catatan"
                              v-model="paymentNote[i]"
                            >
                            </b-form-input>
                        </td>
                      </tr>
                    </template>

                    <!-- potongan -->
                    <tr v-if="tableDisplay.pieceDisplay == true">
                      <td colspan="3" >
                        <span class="font-weight-bold text-info">Potongan</span>
                      </td>
                    </tr>
                    <template v-for="(data, j) in pieceRemuneration">
                      <tr v-bind:key="j">
                        <td class="pl-0">
                            <span class="">{{data.remuneration_type_name}}</span> 
                            <!-- it's for remuneration payment name  -->
                        </td>
                        <td class="text-right">
                          <div class="btn-group btn-group-sm">
                            <b-button
                              squared
                              class="btn btn-sm btn-info square"
                              @click="btnMinQtyPiece(data.id, data.qty, data.ammount, j)"
                            ><i class="fas fa-sm fa-fw fa-minus"></i></b-button>
                            <!-- <span><button class="btn btn-sm btn-block bg-white" disabled>{{ data.qty }}</button></span> -->
                            <b-form-input
                              style="width: 50px"
                              class="input-group text-center"
                              v-model="data.qty"
                              @keyup="btnConfirmPiece(data.qty,j)"
                            >
                            </b-form-input>
                            <b-button
                              squared
                              class="btn btn-sm btn-info"
                              @click="btnAddQtyPiece(data.id, data.qty, data.ammount, j)"
                            ><i class="fas fa-sm fa-fw fa-plus"></i></b-button>
                          </div>
                        </td>
                        <td class="text-right">
                          <span
                            class="font-align-bold"
                          >
                            {{"Rp -"+parseInt(data.ammountPayroll).toLocaleString('id-ID')}}

                            <!-- it's for amount -->
                          </span>
                        </td>
                        <td>
                           <b-form-input
                              v-bind:id="'pieceNote'+j"
                              placeholder="Catatan"
                              v-model="pieceNote[j]"
                              
                            >
                            </b-form-input>
                        </td>
                      </tr>
                    </template>

                    <!-- sub total -->
                    <tr class="font-weight-bold ">
                      <td colspan="2"><span class="font-align-bold">Sub-Total :</span></td>
                      <td class="text-right"><span class="font-align-bold">{{"Rp "+parseInt(subTotal).toLocaleString('id-ID')}}</span></td>
                      <!-- its for sub total -->
                    </tr>
      
                    <!-- pajak -->
                     <tr v-if="tableDisplay.taxDisplay == true">
                      <td colspan="3">
                        <span class="font-weight-bold text-info">Pajak</span>
                      </td>
                    </tr>
                    <template v-for="(tax, k) in taxremuneration">
                      <tr v-bind:key="k">
                        <td class="pl-0">
                            <span class="">{{tax.remuneration_type_name}}</span> 
                            <!-- it's for remuneration payment name  -->
                        </td>
                        <td class="text-right">
                          <div class="btn-group btn-group-sm">
                            <b-button
                              squared
                              class="btn btn-sm btn-info square"
                              @click="btnMinQtyTax(tax.id, tax.qty, tax.ammount, k)"
                            ><i class="fas fa-sm fa-fw fa-minus"></i></b-button>
                            <!-- <span><button class="btn btn-sm btn-block bg-white text-dark" disabled>{{ tax.qty }}</button></span> -->
                            <b-form-input
                              style="width: 50px"
                              class="input-group text-center"
                              v-model="tax.qty"
                              @keyup="btnConfirmTax(tax.qty,k)"
                            >
                            </b-form-input>
                            <b-button
                              squared
                              class="btn btn-sm btn-info"
                              @click="btnAddQtyTax(tax.id, tax.qty, tax.ammount, k)"
                            ><i class="fas fa-sm fa-fw fa-plus"></i></b-button>
                          </div>
                        </td>
                        <td class="text-right">
                          <span
                            class="font-align-bold"
                          >
                            {{parseInt(tax.ammountPayroll)+"%"}}

                            <!-- it's for amount -->
                          </span>
                        </td>
                        <td>
                           <b-form-input
                              v-bind:id="'taxNote'+k"
                              placeholder="Catatan"
                              v-model="taxNote[k]"
                            >
                            </b-form-input>
                        </td>
                      </tr>
                    </template>

                    <!-- sub total -->
                    <tr class="font-weight-bold">
                      <td colspan="2"><span class="font-align-bold">Total :</span></td>
                      <td class="text-right"><span class="font-align-bold">{{"Rp "+parseInt(totalAmmount).toLocaleString('id-ID')}}</span></td>
                      <!-- its for sub total -->
                    </tr>

                  </tbody>
                </table>
              </div>

            </div>
          </div>

        <!-- Input Date of Birth -->
        <b-form-group
          id="input-group-birth-date"
          label="Tanggal Dibayar:"
          label-for="input-sip-birth-date"
        >
          <b-input-group>
            <b-form-datepicker
              id="input-birth-date"
              v-model="form.paid_date"
              required
              locale="id"
              placeholder="Tanggal Dibayar"
            ></b-form-datepicker>
          </b-input-group>
          <small class="text-danger">{{ error.paid_date }}</small>
        </b-form-group>

          <!-- payment method -->
          <b-form-group
          id="input-group-payment"
          label="Metode Pembayaran :"
          label-for="input-payment"
        >
          <b-form-select
            id="input-payment"
            v-model="display.payment_method"
            :options="payments"
            @change="getEwallet"
            required
          ></b-form-select>
            <small class="text-danger">{{ error.payment_method }}</small>
        </b-form-group>

        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button
          class="ml-2"
          variant="default"
          @click="$router.push('/payrolls/list')"
        >
          Batal
        </b-button>
      </b-form>
    </div>
    <ModalEmployee
      @chosenEmployee="setEmployee"
      @submitedEmployee="setEmployee"
    />

  </div>
</template>

<script>
import ModalEmployee from '@/component/general/ModalEmployee.vue'
// import Card from "@/view/content/Card.vue";
import Form from "@/component/beds/Form.vue";
import module from "@/core/modules/CrudModule.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import validation from "@/core/modules/ValidationModule.js";

export default {
  name: "Form",

  props: {
    form: Object,
    route: String,
    employeeID:Number,
    isEdit:Boolean
  },

  components: {
    ModalEmployee,
    Form,
    Treeselect,
  },

  data() {
    return {
      error: {
        employee_id: null,
        remuneration_id: null,
        paid_date: null,
        payment_method: null,
      },
      taxNote:[],
      pieceNote:[],
      paymentNote:[],
      remunerationLoad: false,
      employees: [],
      remunerations: [],
      payments: [],
      eWallet:[],
      paymentRemuneration:[],
      pieceRemuneration:[],
      taxremuneration:[],
      subTotal:0,
      totalAmmount:0,
      remunerationLoad:false,
      display:{
        employee_name:"",
        payment_method:""
      },
      dataRemune:[],
      selectEwallet:false,
      tableDisplay:{
        paymentDisplay:false,
        pieceDisplay:false,
        taxDisplay:false,
      }
    };
  },

  methods: {
    

    setEmployee(value) {
      this.form.employee_id = value.id
      this.display.employee_name = value.name

      this.$bvModal.hide('modal-employee')
      this.getData()
      this.getPaymentRemuneration()

    },

    async getData() {
      
     let response = await module.setTreeSelect('remunerations-employee/'+this.form.employee_id)
        // Success
        if (response.state == 'success') {
          // Set Option Select
          this.remunerations = response.data
          this.remunerations.unshift({ label: 'Pilih Remunerasi', id: '', isDisabled: true })
          this.remunerationLoad = true


      }
    },

    async getPaymentRemuneration(){
      let response = await module.get('remunerations-employee/'+this.form.employee_id)
      // this.paymentRemuneration = response
      // console.log("is edit",this.isEdit);

      this.paymentRemuneration = []
      this.pieceRemuneration = []
      this.taxremuneration = []

      this.subTotal = 0
      this.totalAmmount = 0

      let a,b,c,d,e,f,g,h
      let latestData = []
      let paymentData =[]
      let pieceData = []
      let taxData = [] 
     
      let count = response.length - this.form.remuneration_id.length
        for(a=0;a<response.length;a++){
          if(a < this.form.remuneration_id.length){
            if(response[a].is_tax === 0){
              this.paymentRemuneration.push(response[a])
            }else if(response[a].is_tax === 1){
              this.pieceRemuneration.push(response[a])
            }else if(response[a].is_tax === 2){
              this.taxremuneration.push(response[a])
            }
          }else{
            latestData.push(response[a])
          }
          // console.log(a,response.length-count);
          // if(a < response.length-count){
          //   if(response[a].is_tax === 0){
          //     paymentData.push(response[a])
          //   }else if(response[a].is_tax === 1){
          //     pieceData.push(response[a])
          //   }else if(response[a].is_tax === 2){
          //     taxData.push(response[a])
          //   }
          // }else{
          //   latestData.push(response[a])
          // }
        }
      
      let dataPush = []
      for(b=0;b<this.paymentRemuneration.length;b++){
        dataPush.push(this.paymentRemuneration[b])
      }

      if(this.pieceRemuneration.length > 0){
        for(f=0;f<this.pieceRemuneration.length;f++){
          dataPush.push(this.pieceRemuneration[f])
        }
      }

      if(this.taxremuneration.length > 0){
        for(d=0;d<this.taxremuneration.length;d++){
          dataPush.push(this.taxremuneration[d])
        }
      }

      if(latestData.length > 0){
        for(h=0;h<latestData.length;h++){
          dataPush.push(latestData[h])
        }
      }


      // if(this.isEdit == true){
      // for(c=0;c<dataPush.length;c++){
      //   dataPush[c].qty = this.form.quantity[c]
      //   dataPush[c].ammountPayroll = this.form.remuneration_ammount[c]*this.form.quantity[c]
      // }
      // }

      if(this.isEdit == true){
        if(dataPush.length > this.form.remuneration_id.length){
          let count = dataPush.length - this.form.remuneration_id.length
          for(g=0;g<dataPush.length-count;g++){
            dataPush[g].qty = this.form.quantity[g]
            dataPush[g].remuneration_notes = this.form.remuneration_notes[g]
            dataPush[g].ammountPayroll = this.form.remuneration_ammount[g]*this.form.quantity[g]
          }
        }else{
          for(c=0;c<dataPush.length;c++){
            dataPush[c].qty = this.form.quantity[c]
            dataPush[c].remuneration_notes = this.form.remuneration_notes[c]
            dataPush[c].ammountPayroll = this.form.remuneration_ammount[c]*this.form.quantity[c]
          }
        }
      }

      this.paymentRemuneration = []
      this.pieceRemuneration = []
       this.taxremuneration = []

      for(e=0;e<dataPush.length;e++){
        if(dataPush[e].is_tax === 0){
          this.paymentRemuneration.push(dataPush[e])

          if(this.paymentRemuneration.length > 0){
            this.tableDisplay.paymentDisplay = true
          }
        }else if(dataPush[e].is_tax === 1){
          this.pieceRemuneration.push(dataPush[e])

          if(this.pieceRemuneration.length > 0){
            this.tableDisplay.pieceDisplay = true
          }
        }else if(dataPush[e].is_tax === 2){
          this.taxremuneration.push(dataPush[e])

          if(this.taxremuneration.length > 0){
            this.tableDisplay.taxDisplay = true
          }
        }
      }
      

      

      this.sumSubTotalAmount()
      this.remunerationLoad = true

      

      if(this.isEdit == true && this.remunerationLoad == true){
        let longTax = this.taxremuneration.length
        let longPiece = this.pieceRemuneration.length
        let longPayment = this.paymentRemuneration.length
        let f,g,h
       
        for(f=0;f<longPayment;f++){
          if(this.paymentRemuneration[f].remuneration_notes != ""){
            this.paymentNote[f] = this.paymentRemuneration[f].remuneration_notes
            // document.getElementById('paymentNote'+f).value = this.paymentRemuneration[f].remuneration_notes
          }

        }
        for(g=0;g<longPiece;g++){
          if(this.pieceRemuneration[g].remuneration_notes != ""){
            this.pieceNote[g] = this.pieceRemuneration[g].remuneration_notes
            // document.getElementById('pieceNote'+g).value = this.pieceRemuneration[g].remuneration_notes
          }
         
        }
        for(h=0;h<longTax;h++){
          if(this.taxremuneration[h].remuneration_notes != ""){
            this.taxNote[h] = this.taxremuneration[h].remuneration_notes
            // console.log("val",document.getElementById('taxNote'+h).value);
            // document.getElementById('taxNote'+h).value = this.taxremuneration[h].remuneration_notes
          }
          
        }
      }
    },
   
   async getPayment(){
      let response = await module.setSelectOption('hr-pharmacy-payment-methods')
      this.payments = response.data

    },
    
    // async getPaymentEwallet(){
    //   let response = await module.setSelectOption('pharmacy-payment-methods/get-e-wallets')
    //   this.eWallet = response.data
    // },

    getDisplayData(){
      if(this.form.employee_id != ""){
        this.display.employee_name = this.form.employee_name
        this.display.payment_method = this.form.payment_method
      }
    },

    getEwallet(){
      // this.selectEwallet = false
      // if(this.display.payment_method == 3){
      //   this.selectEwallet = true
        
      // }else{
        this.form.payment_method = this.display.payment_method
      // }
    },
    async formOnSubmit() {
      // Make Request
      if(this.isEdit == true){
        this.form.remuneration_id = []
       this.form.quantity = []
       this.form.remuneration_type_name = []
       this.form.remuneration_ammount = []
       this.form.remuneration_notes = []
      }

      // Get data and push into array
      let a
      for(a=0;a<this.dataRemune.length;a++){
       this.form.remuneration_id.push(this.dataRemune[a].id) 
       this.form.quantity.push(this.dataRemune[a].qty) 
       this.form.remuneration_type_name.push(this.dataRemune[a].remuneration_type_name)
       this.form.remuneration_ammount.push(this.dataRemune[a].ammount)
      }
      this.form.ammount = this.totalAmmount

      // get Notes

      let longTax = this.taxremuneration.length
      let longPiece = this.pieceRemuneration.length
      let longPayment = this.paymentRemuneration.length
      let f,g,h
      // let data = []
      for(f=0;f<longPayment;f++){
        let dataNote = document.getElementById('paymentNote'+f).value
        if(dataNote != ""){
          this.form.remuneration_notes.push(dataNote); 
        }else{
          this.form.remuneration_notes.push(""); 
        }

      }
      for(g=0;g<longPiece;g++){
        let dataNote = document.getElementById('pieceNote'+g).value
        if(dataNote != ""){
          this.form.remuneration_notes.push(dataNote); 
        }else{
          this.form.remuneration_notes.push(""); 
        }

      }
      for(h=0;h<longTax;h++){
        let dataNote = document.getElementById('taxNote'+h).value
        if(dataNote != ""){
          this.form.remuneration_notes.push(dataNote); 
        }else{
          this.form.remuneration_notes.push(""); 
        }
        
      }

      
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
          this.form.remuneration_id = []
          this.form.quantity = []
          this.form.remuneration_type_name = []
          this.form.remuneration_ammount = []
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/payrolls");
      }
    },

    // quantity for payment
    btnMinQtyPayment(id, qty, Amount, index) {
      if(qty === "" || qty === null){
        qty = 0
        this.paymentRemuneration[index].qty = 0
      }
      if(qty > 0){
        this.paymentRemuneration[index].qty = parseInt(this.paymentRemuneration[index].qty) - 1
        this.paymentRemuneration[index].ammountPayroll = parseInt(this.paymentRemuneration[index].ammountPayroll) - parseInt(this.paymentRemuneration[index].ammount)
        this.sumSubTotalAmount()
      }else{
        Swal.fire("Perhatian", "Kuantitas Tidak Boleh Kurang Dari 0", "warning");
      }
    },
    
    btnAddQtyPayment(id, qty, Amount, index) {
      if(qty === "" || qty === null){
        qty = 0
        this.paymentRemuneration[index].qty = 0
      }
      this.paymentRemuneration[index].qty = parseInt(this.paymentRemuneration[index].qty) + 1
      if(qty >= 0){
        this.paymentRemuneration[index].ammountPayroll = parseInt(this.paymentRemuneration[index].ammountPayroll) + parseInt(this.paymentRemuneration[index].ammount)
        this.sumSubTotalAmount()
      }
    },

    btnConfirmPayment(qty, index)
    {
        this.paymentRemuneration[index].qty = qty
        this.paymentRemuneration[index].ammountPayroll = parseInt(this.paymentRemuneration[index].ammount)*qty
        this.sumSubTotalAmount()
    },

   
   // quantity for piece
    btnMinQtyPiece(id, qty, Amount, index) {
      if(qty === "" || qty === null){
        qty = 0
        this.pieceRemuneration[index].qty = 0
      }
      if(qty > 0){
        this.pieceRemuneration[index].qty = parseInt(this.pieceRemuneration[index].qty) - 1
        this.pieceRemuneration[index].ammountPayroll = parseInt(this.pieceRemuneration[index].ammountPayroll) - parseInt(this.pieceRemuneration[index].ammount)
        this.sumSubTotalAmount()
      }else{
        Swal.fire("Perhatian", "Kuantitas Tidak Boleh Kurang Dari 0", "warning");
      }
    },
    
    btnAddQtyPiece(id, qty, Amount, index) {
      if(qty === "" || qty === null){
        qty = 0
        this.pieceRemuneration[index].qty = 0
      }
      this.pieceRemuneration[index].qty = parseInt(this.pieceRemuneration[index].qty) + 1
      if(qty >= 0){
        this.pieceRemuneration[index].ammountPayroll = parseInt(this.pieceRemuneration[index].ammountPayroll) + parseInt(this.pieceRemuneration[index].ammount)
        this.sumSubTotalAmount()
      }
    },

    btnConfirmPiece(qty, index)
    {
        this.pieceRemuneration[index].qty = qty
        this.pieceRemuneration[index].ammountPayroll = parseInt(this.pieceRemuneration[index].ammount)*qty
        this.sumSubTotalAmount()
    },
   
   // quantity for tax
    btnMinQtyTax(id, qty, Amount, index) {
      if(qty === "" || qty === null){
        qty = 0
        this.taxremuneration[index].qty = 0
      }
      if(qty > 0){
        this.taxremuneration[index].qty = parseInt(this.taxremuneration[index].qty) - 1
        this.taxremuneration[index].ammountPayroll = parseInt(this.taxremuneration[index].ammountPayroll) - parseInt(this.taxremuneration[index].ammount)
        this.sumSubTotalAmount()
      }else{
        Swal.fire("Perhatian", "Kuantitas Tidak Boleh Kurang Dari 0", "warning");
      }
    },
    
    btnAddQtyTax(id, qty, Amount, index) {
      if(qty === "" || qty === null){
        qty = 0
        this.taxremuneration[index].qty = 0
      }
      this.taxremuneration[index].qty = parseInt(this.taxremuneration[index].qty) + 1
      if(qty >= 0){
        this.taxremuneration[index].ammountPayroll = parseInt(this.taxremuneration[index].ammountPayroll) + parseInt(this.taxremuneration[index].ammount)
        this.sumSubTotalAmount()
      }
    },

    btnConfirmTax(qty, index)
    {
        this.taxremuneration[index].qty = qty
        this.taxremuneration[index].ammountPayroll = parseInt(this.taxremuneration[index].ammount)*qty
        this.sumSubTotalAmount()
    },

    sumSubTotalAmount(){
      let a,b,c,d
      let data = []
      this.dataRemune = []
      this.subTotal = 0

      for(b=0;b<this.paymentRemuneration.length;b++){
        data.push(this.paymentRemuneration[b])
        this.dataRemune.push(this.paymentRemuneration[b])
      }

      if(this.pieceRemuneration.length > 0){
        for(a=0;a<this.pieceRemuneration.length;a++){
          data.push(this.pieceRemuneration[a])
          this.dataRemune.push(this.pieceRemuneration[a])
        }
      }

      if(this.taxremuneration.length > 0){
        for(d=0;d<this.taxremuneration.length;d++){
          data.push(this.taxremuneration[d])
          this.dataRemune.push(this.taxremuneration[d])
        }
      }

      for(c=0;c<data.length;c++){
        if(data[c].is_tax === 0){
          this.subTotal = this.subTotal + parseInt(data[c].ammount)*data[c].qty
          this.totalAmmount = this.subTotal
        }else if(data[c].is_tax === 1){
          this.subTotal = this.subTotal - parseInt(data[c].ammount)*data[c].qty
          this.totalAmmount = this.subTotal
        }else if(data[c].is_tax === 2){
          // this.totalAmmount = this.subTotal - (this.subTotal*(parseInt(data[c].ammount/100)))
          // console.log("sub",this.subTotal*(parseInt(data[c].ammount)/100));
          // console.log("sub",this.subTotal*((parseInt(data[c].ammount)*data[c].qty)/100));
          // console.log("percent",(parseInt(data[c].ammount)*data[c].qty)/100);
          // console.log("amount",this.subTotal-(this.subTotal*((parseInt(data[c].ammount)*data[c].qty)/100)));
          this.totalAmmount = this.totalAmmount-(this.totalAmmount*((parseInt(data[c].ammount)*data[c].qty)/100))
        }
      }

    }

  },

  // watch: {
  //   'form.employee_name': function (newVal, oldVal) {
  //     this.display.employee_name = newVal
  //   },
  // },

  mounted() {
    // this.getDisplayData()
    this.getPayment()
    // this.getPaymentEwallet()
    // this.getData()
    this.getDisplayData()
    // this.sumSubTotalAmount()
    if(this.isEdit == true){
      this.getPaymentRemuneration()
    }
  },
};
</script>